
//### CookieBot

div#CybotCookiebotDialogBodyLevelWrapper {
    display: flex !important;
  }
  
  div#CybotCookiebotDialogBodyLevelButtonsTable {
    width: auto !important;
  }
  
  .CybotCookiebotDialogBodyButton {
    width: 200px !important;
    height: 45px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    line-height: 1 !important;
    border: none !important ;
  }
  
  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper {
    display: flex !important;
    margin-right: 20px !important;
  }
    
  @media (max-width: 1023px) {
    div#CybotCookiebotDialogBodyLevelWrapper {
        flex-direction: column-reverse;
    }
    
    .CybotCookiebotDialogBodyButton {
        width: auto !important;
        margin-left: 0 !important;
    }
    
     #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper {
        flex-direction: column;
        margin-bottom: 20px;
        margin-right: 0 !important;
        margin-left: 0;
        width: 100%;
    }
  }
  #CybotCookiebotDialog {
    box-shadow: 0px 5px 20px 0px rgba(var(--noir), 0.4)!important;
    opacity: 1!important;
    overflow-y: scroll!important;
  
    a, div {
      font-family: var(--font-family-base), sans-serif!important;
    }
  }
  
  #CybotCookiebotDialogBody {
    max-width: none!important;
  }
  
  #CybotCookiebotDialogBodyContent {
    padding: 30px 15px 15px!important;
  
    @media (min-width: 768px) {
      padding-right: 50px!important;
      padding-left: 50px!important;
    }
  
    @media (min-width: 1280px) {
      padding-right: 100px!important;
      padding-left: 100px!important;
    }
  }
  
  #CybotCookiebotDialogBodyLevelWrapper {
    display: flex!important;
    align-items: center;
    padding: 0 15px 15px!important;
    text-align: left!important;
  
    @media (min-width: 768px) {
      padding-right: 50px!important;
      padding-left: 50px!important;
    }
  
    @media (min-width: 1280px) {
      padding-right: 100px!important;
      padding-left: 100px!important;
    }
  }
  
  #CybotCookiebotDialogBodyLevelButtonAcceptWrapper {
    order: 1!important;
    margin-left: 10px!important;
  
    @media (min-width: 768px) {
      margin-left: 25px!important;
    }
  }
  
  #CybotCookiebotDialogBodyContentText {
    color: #53534f!important;
    font-size: 12px!important;
    line-height: 1.375!important;
  }
  
  #CybotCookiebotDialogBodyLevelButtonsTable {
    @media (max-width: 767px) {
      width: 100%!important;
    }
  }
  
  #CybotCookiebotDialogDetail {
    @media (max-width: 767px) {
      margin: 0 15px!important;
    }
  }
  
  div#CybotCookiebotDialogDetailBody {
    @media (min-width: 768px) {
      margin-left: 50px!important;
    }
  
    @media (min-width: 1280px) {
      margin-left: 100px!important;
    }
  }
  
  #CybotCookiebotDialogBodyContentTitle {
    font-weight: 400!important;
    font-size: 24px!important;
    font-family: var(--font-family-base), sans-serif!important;
    line-height: 1.2!important;
    letter-spacing: -.05em!important;
    text-transform: none;
  }
  
  #CybotCookiebotDialogBodyLevelButtons {
    margin-left: 0!important;
  }
  
  #CybotCookiebotDialogPoweredbyLink {
    display: none!important;
  }
  
  #CybotCookiebotDialogBodyLevelButtonsTable {
    margin: 0!important;
  }
  
  .CybotCookiebotDialogBodyLevelButtonWrapper {
    @media (max-width: 767px) {
      width: 40%!important;
  
      &:not(:last-child) {
        margin-bottom: 10px!important;
      }
    }
  }
  
  #CybotCookiebotDialogDetailBodyContentCookieContainer {
    display: flex!important;
    align-items: center!important;
  }
  
  #CybotCookiebotDialogDetailBodyContentCookieContainerTypeDetails,
  #CybotCookiebotDialogDetailBodyContent a,
  #CybotCookiebotDialogDetailBodyContentTabs a,
  input[type=checkbox].CybotCookiebotDialogBodyLevelButton+label,
  #CybotCookiebotDialogDetailBodyContentTextAbout,
  .CybotCookiebotDialogDetailBodyContentCookieTypeTable tbody td.CybotCookiebotDialogDetailBodyContentCookieTypeTableEmpty {
    color: #53534f!important;
    font-size: 12px!important;
  }
  
  #CybotCookiebotDialogBodyLevelButtonsSelectPane {
    padding: 15px 10px!important;
    border-color: #e6e6e6!important;
  
    @media (max-width: 767px) {
      display: block!important;
    }
  }
  
  #CybotCookiebotDialogDetailBodyContentCookieContainerTypes {
    float: none!important;
  }
  
  #CybotCookiebotDialogDetailBodyContent a:last-child {
    border-bottom: none!important;
  }
  
  .CybotCookiebotDialogDetailBodyContentTab,
  #CybotCookiebotDialogDetailBodyContent,
  .CybotCookiebotDialogDetailBodyContentTabsItem,
  .CybotCookiebotDialogDetailBodyContentCookieContainerTypes,
  .CybotCookiebotDialogDetailBodyContentCookieTypeTable tbody td.CybotCookiebotDialogDetailBodyContentCookieTypeTableEmpty {
    border-color: #e6e6e6!important;
  }
  
  .CybotCookiebotDialogDetailBodyContentTabsItem,
  .CybotCookiebotDialogDetailBodyContentCookieContainerTypes {
    background-color: var(--sable)!important;
  }
  
  .CybotCookiebotDialogDetailBodyContentCookieContainerTypesSelected,
  .CybotCookiebotDialogDetailBodyContentTabsItemSelected {
    background-color: var(--blanc)!important;
  }
  
  #CybotCookiebotDialogDetailBodyContent {
    height: auto!important;
    border-bottom-width: 1px!important;
  }
  
  #CybotCookiebotDialogBodyLevelDetailsWrapper {
    padding: 15px 5px!important;
    vertical-align: middle!important;
    background-color: var(--sable)!important;
    border-color: #e6e6e6!important;
  
    @media (max-width: 767px) {
      display: flex!important;
      justify-content: center;
      align-items: center;
      border-top: none!important;
      border-left: 1px solid #e6e6e6!important;
    }
  }
  
  a#CybotCookiebotDialogBodyLevelButtonAccept {
    height: 50px!important;
    margin-top: 0!important;
    padding: 0 2rem!important;
    font-weight: 600!important;
    font-size: 11px!important;
    line-height: 50px!important;
    letter-spacing: 0.1em!important;
    text-align: center!important;
    text-transform: uppercase!important;
    background-color: var(--vertImperial)!important;
    border-color: var(--vertImperial)!important;
    transition: .3s background cubic-bezier(0.55, 0.055, 0.675, 0.19), .3s color cubic-bezier(0.55, 0.055, 0.675, 0.19), .3s border cubic-bezier(0.55, 0.055, 0.675, 0.19);
  
    &:hover {
      color: var(--vertImperial)!important;
      background-color: transparent!important;
    }
  }
  
  input[type=checkbox].CybotCookiebotDialogBodyLevelButton {
    + label {
      padding-left: 20px!important;
  
      @media (min-width: 768px) {
        padding-left: 25px!important;
      }
  
      &:before,
      &:after {
        position: absolute;
        content: '';
      }
    
      &:before {
        top: -3px;
        left: 0;
        width: 20px;
        height: 20px;
        border: 1px solid #666666;
        border-radius: 5px;
  
        @media (max-width: 767px) {
          top: 1px;
          left: 1px;
          width: 15px;
          height: 15px;
        }
      }
    
      &:after {
        top: 4px;
        left: 4px;
        width: 11.4px;
        height: 8.6px;
        background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDE3IDEzIj4KICAgIDxwYXRoIGZpbGw9IiM3NzciIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTEuMjE4IDYuNUwwIDguMDIgNS4zOTcgMTMgMTcgMS4zMzUgMTUuNzQgMCA1LjM5NiAxMC41NjV6Ii8+Cjwvc3ZnPgo=') no-repeat;
        background-size: 11.4px 8.6px;
        opacity: 0;
        transition: .3s opacity cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }
  
    &:checked + label {
      &:after {
        opacity: 1;
      }
    }
  }
  
  // hide construction message
  
  body > div > span[style="font-weight: bold;"] {
    display: none;
  }
  
  .CookieDeclaration {
    display: none;
  }
  
  